@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.container {

}

.list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding: 0 11/16+rem;
}

.block {
	flex: 0 0 33.333%;
	padding: 5/16+rem;
}

.item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 96/16+rem;
	cursor: pointer;
	transition: .25s;
	text-decoration: none;

	&:hover {
		background: $titan-white;
	}

	&Title {
		transition: color .25s;
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: $silver-chalice;
	}

	&:hover & {

		&Title {
			color: $conflower-blue;
		}
	}

	&Icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 22/16+rem;
		height: 20/16+rem;
		width: 20/16+rem;

		svg {
			width: 100%;
			height: auto;
		}

		path {
			fill: $gray;
		}
	}
}

