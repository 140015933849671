@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 50/16+rem;
	min-height: 211/16+rem;
	border: 1/16+rem dashed #cfcfcf;
	@include f1-700();
	font-size: 14/16+rem;
	line-height: 17/14+em;
	color: #000;

	span {
		@include f1-500();
		color: $silver-chalice;
	}
}