@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";

.component {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	overflow: hidden;

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
	}
}

img {
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
}

.site {
	position: absolute;
	top: 50%;
	bottom: 0;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 100%;
	height: 0;
	overflow: hidden;

	&._desktop {
		padding-bottom: 2316/2788*100%;
		font-size: 1/247+em; // basic size for inner elements

		.wrapper {
			visibility: visible;
			top: 122/2316*100%;
			left: 114/2788*100%;
			right: 114/2788*100%;
			height: 1440/2316*100%;
		}
	}

	&._tv {
		padding-bottom: 816/1307*100%;
		font-size: 1/287+em;

		.wrapper {
			visibility: visible;
			top: 14/816*100%;
			left: 12/1307*100%;
			right: 12/1307*100%;
			height: 721/816*100%;
		}
	}

	&._tablet {
		padding-bottom: 2652/1920*100%;
		font-size: 1/161+em;

		.wrapper {
			visibility: visible;
			top: 225/2652*100%;
			left: 150/1920*100%;
			right: 150/1920*100%;
			height: 2160/2652*100%;
		}
	}

	&._mobile {
		padding-bottom: 1472/718*100%;
		font-size: 1/74+em;

		.wrapper {
			visibility: visible;
			top: 168/1472*100%;
			left: 49/718*100%;
			right: 49/718*100%;
			height: 1104/1472*100%;
		}
	}
}

.wrapper {
	visibility: hidden;
	position: absolute;
	overflow: auto;
}

.container {
	margin: 0 auto;
	width: (247 - 38*2)/247*100%;
}

.site {

	&._tablet {

		.container {
			width: (161 - 5*2)/161*100%;
		}
	}

	&._mobile {

		.container {
			width: (74 - 3*2)/74*100%;
		}
	}
}

.header {
	height: 10em;
	border-bottom: 1em solid #979797;

	.container {
		display: flex;
		align-items: center;
		height: 100%;
	}

	&Item {
		width: 15em;
		height: 4em;
		border-radius: 2em;
		background: #D8D8D8;

		&:global(._active) {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: -3em;
				left: 0;
				right: 0;
				height: 2em;
				background: #B92B27;
			}
		}

		&:not(:last-child) {
			margin-right: 4em;
		}
	}
}

.site._mobile {

	.header {
		justify-content: space-between;
		border: none;
		background: #B92B27;

	}
}

.logo {
	margin-right: 12em;
	width: 8em;
	height: 8em;
	border-radius: 50%;
	background: #B92B27;
}

.site._mobile {

	.logo {
		margin: 0;
		width: 5em;
		height: 5em;
		background: #fff;
	}
}

.search {
	margin-left: 8em;
	margin-right: 2em;
	width: 32em;
	height: 4em;
	border: 1em solid #D8D8D8;
}

.profile {

	&Image {
		margin-left: auto;
		margin-right: 2em;
		width: 4em;
		height: 4em;
		border-radius: 50%;
		background: #D8D8D8;
	}

	&Name {
		width: 22em;
		height: 4em;
		background: #B92B27;
	}
}

.site._mobile {

	.profile {

		&Name {
			margin-left: 2em;
			width: 5em;
			height: 4em;
			background: #fff;
			border-radius: 2em;
		}
	}
}

.main {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 7em;

	&Header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 5em;
		padding: 6em;
		background: #D8D8D8;

		&Item {
			width: 100%;
			background: #fff;

			&:not(:last-child) {
				margin-bottom: 4em;
				height: 4em;
				border-radius: 2em;
			}

			&:first-child {
				max-width: 30em;

			}

			&:nth-child(2) {
				max-width: 55em;

			}

			&:nth-child(3) {
				max-width: 30em;
				height: 6em;
				border-radius: 3em;
			}
		}
	}
}

.content {
	flex: 1 1 auto;
}

.block {
	padding: 2em;
	background: #D8D8D8;

	&:not(:last-child) {
		margin-bottom: 5em;
	}
}

.row {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 2em;
	}
}

.item {
	height: 4em;
	border-radius: 2em;
	background: #fff;

	&:not(:last-child) {
		margin-right: 2em;
	}
}

.image {
	width: 100%;
	background: #fff;
}

.leftSidebar {
	padding-right: 9em;
	width: 33em;

	&Item {
		width: 24em;
		height: 4em;
		border-radius: 2em;
		background: #D8D8D8;

		&:not(:last-child) {
			margin-bottom: 4em;
		}
	}
}

.site {

	&._tablet {

		.leftSidebar {
			visibility: hidden;
		}
	}

	&._mobile {

		.leftSidebar {
			display: none;
		}
	}
}

.rightSidebar {
	margin-left: 8em;
	padding: 3em 3em 6em;
	width: 28em;
	background: #D8D8D8;

	&Item {
		height: 4em;
		border-radius: 2em;
		background: #fff;

		&:first-child {
			height: 6em;
			border-radius: 3em;
		}

		&:not(:last-child) {
			margin-bottom: 4em;
		}
	}
}

.site {

	&._mobile {

		.rightSidebar {
			display: none;
		}
	}
}

.site._mobile {

	.title {
		margin: 0 auto;
		width: 16em;
		height: 6em;
		border-radius: 3em;
		background: #fff;
	}
}