@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 1rem;
}

.row {
	margin-bottom: 1rem;
}

.label {
	margin-bottom: .25rem;
	color: $gray;
}

.block {
	padding: 10/16+rem;
	border: 1/16+rem solid $alto-1;
	border-radius: .25rem;
	background: #fff;
	box-shadow: 0 0 12/16+rem 0 rgba($mercury,.5);

	& + .block {
		margin-top: .5rem;
	}
}

.blockHeader {
	display: flex;
	align-items: center;
	margin-bottom: 13/16+rem;

	:global {

		.checkbox {
			margin-right: .5rem;

			svg {
				margin: 0;
			}
		}

		.tooltip {
			margin-left: auto;
			cursor: pointer;

			svg {
				width: 14/16+rem;
				height: auto;

				path {
					fill: $gray;
				}
			}
		}
	}
}

.blockSize {
	@include f1-700();
}

.blockContent {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.blockList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.blockItem {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: .5rem;
	}

	:global {

		svg {
			margin-left: .5rem;
			width: 1.5rem;
			height: auto;
			cursor: pointer;
		}
	}
}

.blockUpload {
	align-self: flex-start;
	margin-bottom: 11/16+rem;
}

.blockBtn {
	display: flex;
	align-items: center;
	padding: 0 12/16+rem;
	height: 25/16+rem;
	border-radius: 25/2/16+rem;
	background: $silver-chalice;

	span {
		flex: 0 1 auto;
		margin-right: .5rem;
		overflow: hidden;
		color: #fff;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.blockItemRemove {
	margin-left: auto;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	background: #bebebe;

	&:before,
	&:after {
		content: '';
		width: 10/16+rem;
		height: 1/16+rem;
		background: #dedede;
	}
}