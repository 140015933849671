// Placeholder text
@mixin placeholder($color, $opacity) {
    // Firefox
    &::-moz-placeholder {
        color: $color;
        opacity: $opacity; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    }
    &:-ms-input-placeholder {
        color: $color;
        opacity: $opacity;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder  {
        color: $color;
        opacity: $opacity;
    } // Safari and Chrome
}

//sprite backgrounds
@mixin spriteX($name1, $name2: false, $name3: false) {

    @include sprite($name1);

    @if $name2 {

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) {
            @include sprite($name2);

            background-size: nth($spritesheet-2, 1) / nth($name2, 5) * 100%;
            background-position:
                nth($name2, 3) / (nth($name2, 5) / nth($name1, 5))
                nth($name2, 4) / (nth($name2, 5) / nth($name1, 5));
        }
    }

    @if $name3 {

        @media
        only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (   min--moz-device-pixel-ratio: 3),
        only screen and (     -o-min-device-pixel-ratio: 3/1),
        only screen and (        min-device-pixel-ratio: 3),
        only screen and (                min-resolution: 288dpi),
        only screen and (                min-resolution: 3dppx) {
            @include sprite($name3);

            background-size: nth($spritesheet-3, 1) / nth($name3, 5) * 100%;
            background-position:
                nth($name3, 3) / (nth($name3, 5) / nth($name1, 5))
                nth($name3, 4) / (nth($name3, 5) / nth($name1, 5));
        }
    }

    width: nth($name1, 5) !important;
    height: nth($name1, 6) !important;
}

//background mixin
@mixin backgroundImage($name1, $name2: false, $name3: false) {

    background-image: url($name1);

    @if $name2 {

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) {

            background-image: url($name2);
        }
    }

    @if $name3 {

        @media
        only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (   min--moz-device-pixel-ratio: 3),
        only screen and (     -o-min-device-pixel-ratio: 3/1),
        only screen and (        min-device-pixel-ratio: 3),
        only screen and (                min-resolution: 288dpi),
        only screen and (                min-resolution: 3dppx) {

            background-image: url($name3);
        }
    }
}
//CENTER
@mixin center($xy:xy) {
    @if $xy==xy {
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        @include transform(translateX(-50%) translateY(-50%));
    }
    @else if $xy==x {
        left: 50%;
        right: auto;
        @include transform(translateX(-50%));
    }
    @else if $xy==y {
        top: 50%;
        bottom: auto;
        @include transform(translateY(-50%));
    }
}

//FONTS
@mixin f1-700 {
    font-family: 'Lato Bold', sans-serif;
    font-weight: 400;
}

@mixin f1-300 {
    font-family: 'Lato Light', sans-serif;
    font-weight: 400;
}

@mixin f1-400 {
    font-family: 'Lato Regular', sans-serif;
    font-weight: 400;
}

@mixin f1-400i {
    font-family: 'Lato Italic', sans-serif;
    font-weight: 400;
}

@mixin f1-500 {
    font-family: 'Lato Medium', sans-serif;
    font-weight: 400;
}

@mixin f1-900 {
    font-family: 'Lato Black', sans-serif;
    font-weight: 400;
}

//media
//xlg
@mixin under-xlg() {
    @media (max-width: ($xlg - 1)+px) {
        @content
    }
}
@mixin over-xlg() {
    @media (min-width: $xlg+px) {
        @content
    }
}

//lg
@mixin under-lg() {
    @media (max-width: ($lg - 1)+px) {
        @content
    }
}
@mixin over-lg() {
    @media (min-width: $lg+px) {
        @content
    }
}

//md
@mixin under-md() {
    @media (max-width: ($md - 1)+px) {
        @content
    }
}
@mixin over-md() {
    @media (min-width: $md+px) {
        @content
    }
}

//sm
@mixin under-sm() {
    @media (max-width: ($sm - 1)+px) {
        @content
    }
}
@mixin over-sm() {
    @media (min-width: $sm+px) {
        @content
    }
}

//xs
@mixin under-xs() {
    @media (max-width: $xs+px) {
        @content
    }
}
@mixin over-xs() {
    @media (min-width: ($xs + 1)+px) {
        @content
    }
}

//almost for panda-components table cell width
@mixin flex-width($width) {
    flex: 0 1 $width/16+rem;
    min-width: $width/16+rem;
    max-width: $width/16+rem;
}