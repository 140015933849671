@import "~factor/src/scss/variables";
@import "src/scss/variables";

.container {
	display: flex;
	align-items: center;
	padding-left: 25/16+rem;
	padding-right: 28/16+rem;
	min-height: 75/16+rem;

	:global(.btn-round) {

		&:not(:last-child) {
			margin-right: 10/16+rem;
		}
	}
}

.selected {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	font-size: 17/16+rem;
	line-height: 21/17+em;
	color: $dodger-blue-1;
}

.remove {
	margin-right: 14/16+rem;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	background: $alto;

	&:before,
	&:after {
		width: 10/16+rem;
		height: 1/16+rem;
		background: #fff;
	}
}

.manageBtn {

	:global(.btn-content) {

		svg {
			position: absolute;
			top: 50%;
			right: 1rem;
			transform: translateY(-50%);
			width: .5rem;
			height: auto;

			path {
				fill: $conflower-blue;
			}
		}
	}
}

.search {
	width: 210/16+rem;
}

.tableBtn {
	margin-left: auto;
}