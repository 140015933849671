@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.sidebar {
	position: fixed;
	z-index: 20;
	top: 70/16+rem;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	width: 320/16+rem;
	border-right: 1/16+rem solid $wild-sand;
	background: #fff;
	box-shadow: 0 0 0.5rem 0 rgba(0,0,0,.25);
	transition: transform linear 0.3s;

	&:global {

		&._hidden {
			transform: translateX(-100%);

			:local {

				.container {
					opacity: 0;
					pointer-events: none;
					transition: opacity 0s 0.3s;
				}

				.arrow {
					transition: transform 0.3s 0.3s;
					transform: translateX(30/16+rem);

					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		&._not-fixed {
			position: static;
		}

		&._right-sidebar {
			width: 320/16+rem;
			box-shadow: 0 0 .25rem 0 rgba($gray,.5);
		}
	}
}

.arrow {
	@extend %button;
	position: absolute;
	z-index: 2;
	bottom: 12/16+rem;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30/16+rem;
	height: 30/16+rem;
	background: $melrose;
	cursor: pointer;
	transition: transform 0.3s, background 0.3s;

	&:hover {
		background: $indigo;
	}
}

.container {
	flex: 1 1 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.header {
	flex: 0 0 auto;
	padding: 1rem;
	border-bottom: 1/16+rem solid $wild-sand;
}

.sidebar {

	&:global {

		&._mainpage {

			:local {

				.header {
					border: none;
				}
			}
		}
	}
}

.title {
	@include f1-700();
	font-size: 20/16+rem;
	line-height: 24/20+em;

	span {
		@include f1-500();
		color: $silver-chalice;
	}
}

.creative {
	display: flex;
	align-items: center;
	padding: 10/16+rem 1rem;
	border-bottom: 1/16+rem solid $wild-sand;

	&Icon {
		flex: 0 0 24/16+rem;
		margin-right: 1rem;
		line-height: 0;
	}

	span {
		flex: 1 1 auto;
		overflow: hidden;
		@include f1-700();
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.main {
	flex: 1 1 auto;
	width: 100%;
	overflow: auto;
}

.content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 10/16+rem 1rem;
}