@import "~factor/src/scss/variables";
@import "src/scss/variables";
@import "src/scss/mixins";
@import "src/scss/button";

.container {
	display: flex;
	align-items: stretch;
	padding-right: 28/16+rem;
	height: 87/16+rem;
	background: #fff;
	transition: background .25s, border .25s;;

	&:global {

		&._active {
			background: $titan-white;
		}

		&._tile {
			position: relative;
			flex-direction: column;
			padding: 0;
			height: auto;
			border-radius: .25rem;
			border: 1/16+rem solid $alto-1;
			box-shadow: 0 0 .75rem 0 rgba($mercury,.5);
			cursor: pointer;

			&._active {
				background: #fff;
				border-color: #97A3F2;
			}

			:local {

				.checkbox {
					display: none;
				}

				.image {
					margin: 0;
					width: 100%;
					height: 219/16+rem;
					max-height: 219/16+rem;
					min-height: 219/16+rem;
					border-radius: .25rem .25rem 0 0;
					overflow: hidden;
				}

				.info {
					align-self: stretch;
					display: block;
					padding: .5rem 1rem 1rem;
					min-height: 91/16+rem;
					border-radius: 0 0 .25rem .25rem;

					&Row {
						display: block;
						margin: 0;
					}
				}

				.title {

					h4 {
						margin-bottom: .5rem;
						@include f1-700();
					}
				}

				.icon {
					position: absolute;
					top: 14/16+rem;
					left: 1rem;
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0;
					width: 40/16+rem;
					height: 40/16+rem;
					border-radius: 50%;
					background: #fff;
					
					svg {
						width: 1.5rem;
					}
				}

				.uploaded,
				.size {
					@include f1-500();

					span {
						display: inline;
					}
				}

				.uploaded {
					margin-bottom: .5rem;
				}

				.status {
					position: absolute;
					right: 7/16+rem;
					bottom: 10/16+rem;
					padding: 0;
					width: .5rem;
					height: .5rem;
					min-width: 0;
					max-width: .5rem;
					border-radius: 50%;
					font-size: 0;
					line-height: 0;
					color: transparent;
				}

				.preview {
					position: absolute;
					top: 219/2/16+rem;
					left: 50%;
					transform: translate(-50%, -50%);
					height: 40/16+rem;
					border-radius: 20/16+rem;
				}

				.more {
					position: absolute;
					top: (219 + 8) / 16+rem;
					right: 2/16+rem;

					&:global {

						&._tile {

							&._opened {
								top: 219/16+rem;
								right: 0;
							}
						}
					}
				}
			}

			&._total {
				padding: 1rem;

				&:after {
					content: 'Select';
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 -1rem -1rem;
					border-top: 1/16+rem solid $wild-sand;
					min-height: 42/16+rem;
					color: $silver-chalice;
				}

				:local {

					.info {
						padding: 0 0 20/16+rem;

						&Item {

							&:not(:last-child) {
								margin: 0 0 20/16+rem;
							}

							&:global {

								&._creatives-number {
									align-items: center;
								}
							}
						}

						&Wrapper {
							justify-content: space-between;

							.infoItem {
								flex: 1 1 50%;
								margin-bottom: 0;
							}
						}
					}

					.title {
						margin-bottom: 3/16+rem;

						h4 {
							margin-bottom: 0;
						}
					}

					.more {
						top: .5rem;
						right: 0;
					}
				}
			}
		}
	}
}

.checkbox {
	flex: 0 0 81/16+rem;
	display: flex;
	justify-content: center;
	align-items: center;

	:global .checkbox {

		&__label {

			svg {
				margin: 0;
				width: 24/16+rem;
				height: auto;
			}
		}
	}
}

.image {
	margin-right: 2rem;
	width: 116/16+rem;
	line-height: 0;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.info {
	align-self: center;
	padding: .5rem 0;

	&Row {
		display: flex;
		align-items: flex-end;
		margin-top: 7/16+rem;
	}

	&Item {
		@include f1-300();
		font-size: 12/16+rem;
		line-height: 15/12+em;
		color: $dove-gray-1;

		span {
			display: block;
		}

		&:not(:last-child) {
			margin-right: 2rem;

			&:global {

				&._creatives-number {
					display: flex;
					align-items: flex-end;
				}
			}
		}
	}

	&Wrapper {
		display: flex;
		align-items: flex-end;
	}

	&Title {
		margin-right: 12/16+rem;
	}
}

.title {
	display: flex;
	align-items: center;

	h4 {
		font-weight: 400;
		color: $mine-shaft-1;
	}
}

.icon {
	margin-right: .75rem;
	width: 1rem;
	line-height: 0;

	svg {
		width: 100%;
		height: auto;
	}
}

.uploaded,
.size {
	@include f1-300();
	font-size: 12/16+rem;
	line-height: 15/12+em;
	color: $dove-gray-1;

	span {
		display: block;
	}
}

.uploaded {
	margin-right: 2rem;
}

.size {
	margin-right: 26/16+rem;
}

.status {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 .5rem;
	min-width: 104/16+rem;
	height: 25/16+rem;
	border-radius: 25/2/16+rem;
	color: #fff;
	text-transform: capitalize;
	text-align: center;

	&:global {

		&._rejected {
			background: $pomegranate;
		}

		&._pending {
			background: $manhattan;
		}

		&._approved {
			background: $fruit-salad;
		}
	}
}

.preview {
	@extend %button;
	align-self: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: .5rem;
	padding: 0 .5rem;
	min-width: 109/16+rem;
	height: 25/16+rem;
	border-radius: 25/2/16+rem;
	background: $titan-white;
	box-shadow: 0 0 .25rem 0 $silver-chalice;
	cursor: pointer;
	@include f1-400();
	font-size: 14/16+rem;
	line-height: 17/14+em;
	color: $conflower-blue;

	svg {
		margin-right: .5rem;
		width: 1rem;
		height: auto;
	}
}

.more {
	align-self: center;
}

.statuses {
	display: flex;
	align-items: flex-end;

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25/16+rem;
		height: 25/16+rem;
		border-radius: 50%;
		@include f1-400();
		font-size: 14/16+rem;
		line-height: 17/14+em;
		color: #fff;

		&:not(:last-child) {
			margin-right: 6/16+rem;
		}

		&:global {

			&._approved {
				background: $fruit-salad;
			}

			&._pending {
				background: $manhattan;
			}

			&._rejected {
				background: $pomegranate;
			}
		}
	}
}

.edit {
	align-self: center;
	margin-left: auto;
}